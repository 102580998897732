import React, { useState, useEffect, useRef } from 'react';
import './section1.css';
import leftImage from './left-image.png';
import rightImage from './right-image.png';
import topLeftImage from './top-left-image.png';
import bottomRightImage from './bottom-right-image.png';

const Section1 = () => {
  const smartContractAddress = '0x00000012123123123123123';
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const copyToClipboard = async (e) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(smartContractAddress);
      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('Copy'), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      alert('Failed to copy text.');
    }
  };

  const handleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.clientHeight;
      const scrollPos = window.pageYOffset;

      if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
        setScrollPosition(scrollPos - sectionTop);
      } else {
        setScrollPosition(0);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const getShortAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div ref={sectionRef} className="section1-container">
      <div className="section1-content">
        <div className="section1-smart-contract">
          <div className="section1-smart-contract-address">
            {isMobile ? getShortAddress(smartContractAddress) : smartContractAddress}
          </div>
          <button className="section1-copy-button" onClick={copyToClipboard}>
            {copyButtonText}
          </button>
        </div>
        <div className="section1-title">
          <h2>MY Universe</h2>
          <p>Step into my world, where everything revolves around me.</p>
        </div>
        <div className="section1-grid">
          <div className="section1-grid-item">
            <h4>Preorder Hopium</h4>
            <p>Preorder my not so edge but cutting album, "Hopium." Preorder via RWA and enjoy reduced taxes! Because who wouldn't want to save money while experiencing my genius?</p>
          </div>
          <div className="section1-grid-item">
            <h4>Donate to Me</h4>
            <p>Life as the internet's queen isn't easy. Support my fabulous lifestyle, because creating the content you love doesn't come cheap. Trade your money for my brilliance.</p>
          </div>
          <div className="section1-grid-item">
            <h4>Base Summer</h4>
            <p>We're, well I mean ME heating up the BASE with my unstoppable influence. Witness how I make everything better. Because it's all about me, obviously Anon.</p>
          </div>
        </div>
      </div>
      <img
        src={leftImage}
        alt="Left"
        className="section1-image section1-image-left hide-on-mobile"
        style={{ transform: `translateY(${scrollPosition * 0.2}px) rotate(-10deg)` }}
      />
      <img
        src={rightImage}
        alt="Right"
        className="section1-image section1-image-right hide-on-mobile"
        style={{ transform: `translateY(${scrollPosition * 0.2}px) rotate(10deg)` }}
      />
      <img
        src={topLeftImage}
        alt="Top Left"
        className="section1-image section1-image-top-left hide-on-mobile"
        style={{ transform: `translateY(${scrollPosition * 0.1}px) rotate(5deg)` }}
      />
      <img
        src={bottomRightImage}
        alt="Bottom Right"
        className="section1-image section1-image-bottom-right hide-on-mobile"
        style={{ transform: `translateY(${scrollPosition * 0.3}px) rotate(-15deg)` }}
      />
    </div>
  );
}

export default Section1;
