import React, { useState, useEffect, useRef } from 'react';
import { FaTwitter, FaTelegram, FaPlay, FaPause, FaStepForward, FaStepBackward, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { songs } from './config/songs';

function Header() {
  const [visible, setVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const lastScrollY = useRef(0);
  const scrollTimer = useRef(null);

  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY < lastScrollY.current) {
        // Scrolling up
        setVisible(true);
      } else if (currentScrollY > 100 && currentScrollY > lastScrollY.current) {
        // Scrolling down and past the threshold
        setVisible(false);
      }

      lastScrollY.current = currentScrollY;

      // Clear the existing timer
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }

      // Set a new timer to show the header after scrolling stops
      scrollTimer.current = setTimeout(() => {
        setVisible(true);
      }, 1000); // Adjust this value to change how long after scrolling stops before the header appears
    };

    window.addEventListener('scroll', controlHeader);

    return () => {
      window.removeEventListener('scroll', controlHeader);
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', () => {});
    };
  }, []);

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const nextSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
    setIsPlaying(true);
    setTimeout(() => audioRef.current.play(), 0);
  };

  const prevSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex - 1 + songs.length) % songs.length);
    setIsPlaying(true);
    setTimeout(() => audioRef.current.play(), 0);
  };

  const handleProgress = (e) => {
    const clickPosition = (e.pageX - e.currentTarget.offsetLeft) / e.currentTarget.offsetWidth;
    audioRef.current.currentTime = clickPosition * duration;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.muted = !isMuted;
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    setIsMuted(newVolume === 0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <header className={`sticky top-0 z-50 bg-sky-950 text-white p-3 transition-all duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex justify-between items-center">
        <div className="flex items-center w-1/4">
          <button onClick={togglePlay} className="mr-2 text-xl">
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={prevSong} className="mr-2 text-lg">
            <FaStepBackward />
          </button>
          <button onClick={nextSong} className="mr-2 text-lg">
            <FaStepForward />
          </button>
          <div className="text-sm truncate">{songs[currentSongIndex].title}</div>
        </div>

        <div className="w-1/2 overflow-hidden whitespace-nowrap">
          <div className="animate-marquee">
            <span>
              📰 Latest News | 🚀 Exciting Updates | 🌟 Stay Tuned for More! 🌟 |
            </span>
            <span>
              📰 Latest News | 🚀 Exciting Updates | 🌟 Stay Tuned for More! 🌟 |
            </span>
          </div>
        </div>

        <div className="flex items-center w-1/4 justify-end">
          <a href="https://twitter.com/placeholder" target="_blank" rel="noopener noreferrer" className="mr-4">
            <FaTwitter className="text-xl" />
          </a>
          <a href="https://t.me/placeholder" target="_blank" rel="noopener noreferrer">
            <FaTelegram className="text-xl" />
          </a>
        </div>
      </div>

      <div className="mt-2 flex items-center">
        <div className="flex-grow mr-4">
          <div className="bg-gray-600 h-1 w-full rounded-full cursor-pointer" onClick={handleProgress}>
            <div className="bg-blue-500 h-1 rounded-full" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
          </div>
        </div>
        <div className="text-xs mr-4">{formatTime(currentTime)} / {formatTime(duration)}</div>
        <button onClick={toggleMute} className="mr-2">
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="w-20"
        />
      </div>

      <audio
        ref={audioRef}
        src={songs[currentSongIndex].url}
        onEnded={nextSong}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </header>
  );
}

export default Header;