import { useEffect, useRef } from 'react';

const WebSocketClient = ({ onUpdate, onDonationUpdate }) => {
  const socketRef = useRef(null);

  useEffect(() => {
    const connectWebSocket = () => {
      const socket = new WebSocket('wss://websocket.brity.fm'); // Correct WebSocket URL
      socketRef.current = socket;

      socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data); // Debugging line
          switch (data.type) {
            case 'donationUpdate':
              onDonationUpdate(data);
              break;
            case 'statsUpdate':
              onUpdate(data);
              break;
            default:
              console.log('Unknown message type:', data.type);
          }
        } catch (error) {
          console.error('Error parsing WebSocket data:', error);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed', event.code, event.reason);
        setTimeout(connectWebSocket, 5000); // Try to reconnect after 5 seconds
      };
    };

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [onUpdate, onDonationUpdate]);

  return null;
};

export default WebSocketClient;
