import React, { useState } from 'react';
import './footer.css';
import WebSocketClient from './WebSocketClient';

function Footer() {
  const [stats, setStats] = useState({
    currentAPY: 'X',
    totalValueLocked: 'X,XXX,XXX',
    activeUsers: 'XX,XXX',
    governanceProposals: 'XX'
  });

  const handleWebSocketUpdate = (newData) => {
    console.log('Received WebSocket data:', newData);
    
    setStats({
      currentAPY: typeof newData.currentAPY === 'number' ? newData.currentAPY.toFixed(2) : 'N/A',
      totalValueLocked: typeof newData.totalValueLocked === 'number' ? new Intl.NumberFormat('en-US').format(newData.totalValueLocked) : 'N/A',
      activeUsers: typeof newData.activeUsers === 'number' ? new Intl.NumberFormat('en-US').format(newData.activeUsers) : 'N/A',
      governanceProposals: newData.governanceProposals || 'N/A'
    });
  };

  const faqItems = [
    { 
      question: "Who's Brity?", 
      answer: "OMG, it's me! I'm like, this super talented 19y-old pop star, and I've got my own crypto shitcoin called $BRITY. It's totally fetch! So buy it simp"
    },
    { 
      question: "Are you for real?", 
      answer: "Duh! I'm as real as my music (what is AI) and as iconic as your fave inFLEXncer. But like, with better hair and ass."
    },
    { 
      question: "What's the deal with $BRITY?", 
      answer: "$BRITY is like, my special token or a way to fund my life. It's totes not just another boring crypto thing. It's got star power, just like yours truly!"
    },
    { 
      question: "Why's there a 1% tax thingy?", 
      answer: "It's for keeping things running smooth, babe. Think of it as your VIP ticket to the Brity show. Who knows? Maybe you'll get a lambo out of it! (JK, don't quote me on that)"
    },
    { 
      question: "How do I get my hands on $BRITY?", 
      answer: "Easy peasy! Just swap your old, lame coins for $BRITY on any big DEX. It's like shopping, but way more fun!"
    },
    { 
      question: "What's this 'Hopium' album about?", 
      answer: "Only the hottest tracks to ever hit the blockchain! It's my debut album, and it'll make you forget all about those crypto blues. Trust!"
    },
    { 
      question: "Can I, like, stake my $BRITY?", 
      answer: "Totally! Staking $BRITY is like getting free merch at my concert. More $BRITY for you, more love from me!"
    },
    { 
      question: "How do I get in on Brity's decision-making?", 
      answer: "Got $BRITY? Then you've got a say! It's like voting for your fave on a reality show, but way more important."
    },
    { 
      question: "Are you doing NFTs?", 
      answer: "Maybe! I'm thinking about dropping some super exclusive pics. It'll be like, the ultimate guessing game of what I look like. Stay tuned!"
    },
    { 
      question: "Do you care about the environment and stuff?", 
      answer: "Obvi! I'm all about being green. We might even make $BRITY more eco-friendly. I'm calling it 'proof-of-pop' cuz it sounds cuter."
    },
    { 
      question: "Should I invest in $BRITY?", 
      answer: "IDK, that's on you, boo. But remember what I always say: 'When the market's bear, just flip your hair!' (PS: Not financial advice, k?)"
    },
    { 
      question: "How can I talk to you?", 
      answer: "Catch me on Twitter dropping truth bombs, or maybe I'll slide into your DMs if you're lucky! Your best bet? Join my Discord fam, the 'Brity's Bits'!"
    }
  ];

  return (
    <footer className="footer">
      <WebSocketClient onUpdate={handleWebSocketUpdate} />
      <div className="footer-main">
        <div className="footer-section faq-section">
          <h3>FAQ</h3>
          {faqItems.map((item, index) => (
            <details key={index}>
              <summary>{item.question}</summary>
              <p>{item.answer}</p>
            </details>
          ))}
        </div>
        
        <div className="footer-section about-section">
          <h3>About Me (The One and Only Brity!)</h3>
          <p>Hey babes! 👋 It's Brity here, your fave 19-year-old pop sensation and the queen of the hottest crypto on the block! 💁‍♀️🎤</p>
          <p>I burst onto the scene in 2023 like a glitter bomb at a board meeting. My mission? Making money stuff fun and fab! 💸✨</p>
          <p>By day, I'm belting out chart-toppers that'll make your playlist jealous. By night, I'm sprinkling some Brity magic on your boring old wallets. 🎶💰</p>
          <p>My new album "Hopium for the Crypto Winter" isn't just music, it's a vibe! (But like, don't take financial advice from my lyrics, duh!)</p>
          <p>Some haters say I'm just another pretty face. But honey, I'm a whole mood! A crypto queen with a voice that can melt diamonds! 💎🔥</p>
          <p>So buckle up, buttercup! We're going on a wild ride! With a teeny 1% tax (daddy issues much?), we're building a squad that's part money club, part stan army, and 100% awesome!</p>
          <p>Remember my motto: Brity or bust, memes are a must, YOLO or you'll rust! Let's make money fun again, babes! 🎉💖</p>
        </div>
      </div>
      
      <div className="dapp-section">
        <div className="dapp-info">
          <h3>Join Our DApp</h3>
          <p>Stake, provide liquidity, and manage your tokens</p>
          <ul>
            <li>Current APY: {stats.currentAPY}%</li>
            <li>Total Value Locked: ${stats.totalValueLocked}</li>
            <li>Active Users: {stats.activeUsers}</li>
            <li>Governance Proposals: {stats.governanceProposals}</li>
          </ul>
        </div>
        <a href="https://dapp.brity.fm" className="dapp-button" target="_blank" rel="noopener noreferrer">Launch DApp</a>
        </div>
    
      
      <div className="footer-copyright">
        <p>&copy; 2024 Brity. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
