import React from 'react';
import './FlyAnimation.css'; // Importing CSS for animations

function FlyAnimation() {
  // Generate a list of emojis with random starting positions and delays
  const flyingCash = Array.from({ length: 5 }).map((_, index) => (
    <span key={index} className={`flying-cash cash-${index}`}>
      💸
    </span>
  ));

  return <div className="fly-animation">{flyingCash}</div>;
}

export default FlyAnimation;
