import React from 'react';
import Header from './Header';
import Hero from './Hero';
import Content from './Content';
import Roadmap from './Roadmap';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <Content />
        <Roadmap />
      </main>
      <Footer />
    </div>
  );
}

export default App;
