import React, { useState, useEffect, useRef } from 'react';
import WebSocketClient from './WebSocketClient';
import './Roadmap.css';

const Roadmap = () => {
  const [donationProgress, setDonationProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const roadmapRef = useRef(null);

  const handleWebSocketUpdate = (data) => {
    console.log('Received WebSocket update:', data);
    // Handle statsUpdate or other messages here
  };

  const handleDonationUpdate = (data) => {
    setDonationProgress(data.amount);
    setIsLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          }
        });
      },
      { threshold: 0.5, rootMargin: '0px 0px -100px 0px' }
    );

    const roadmapItems = roadmapRef.current.querySelectorAll('.roadmap-item');
    roadmapItems.forEach((item) => observer.observe(item));

    return () => {
      roadmapItems.forEach((item) => observer.unobserve(item));
    };
  }, [isLoading]);

  const roadmapItems = [
    { icon: '🎤', text: 'Release debut single "Crypto Heartbreak"' },
    { icon: '💃', text: 'Launch Brity Dance Challenge on TikTok' },
    { icon: '🪙', text: 'Introduce $BRITY token staking' },
    { icon: '🎨', text: 'Drop exclusive Brity NFT collection' },
    { icon: '🏆', text: 'Hit 1 million active $BRITY holders' },
    { icon: '🌍', text: 'Global Brity Tour: "Singing to the Moon"' }
  ];

  return (
    <section className="roadmap-section" ref={roadmapRef}>
      <WebSocketClient onUpdate={handleWebSocketUpdate} onDonationUpdate={handleDonationUpdate} />
      <div className="donation-progress">
        <h3>Roadmap & Brity Progress</h3>
        <div className="progress-bar">
          <div 
            className="progress-fill"
            style={{ width: `${Math.min((donationProgress / 1000000) * 100, 100)}%` }}
          ></div>
        </div>
        <p>${donationProgress.toLocaleString()} raised</p>
      </div>

      <h3>Brity's Fabulous Future</h3>
      <div className="roadmap-container">
        <div className="roadmap-path"></div>
        {roadmapItems.map((item, index) => (
          <div key={index} className={`roadmap-item ${isLoading ? 'loading' : ''} ${index % 2 === 0 ? 'left' : 'right'}`}>
            <div className="roadmap-icon">{item.icon}</div>
            <div className="roadmap-content">
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
