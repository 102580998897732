import React, { useState, useEffect } from 'react';
import FlyAnimation from './FlyAnimation';
import './index.css';
import waveSvg from './wave.svg';

const heroBackgroundImage = `/herobackground.png`;

function Hero() {
  const [displayText, setDisplayText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const text = 'BRITY';
    let currentIndex = 0;

    const timer = setInterval(() => {
      setDisplayText(text.slice(0, currentIndex + 1));
      currentIndex++;

      if (currentIndex === text.length) {
        clearInterval(timer);
        setTimeout(() => {
          setIsAnimating(true);
        }, 500);
      }
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="hero-container relative h-screen flex flex-col justify-center items-center" style={{ background: 'linear-gradient(135deg, #00BFFF 0%, #00008B 100%)' }}>
      <div className="preorder-link-container absolute top-4 left-0 w-full flex justify-center" style={{ zIndex: 20 }}>
        <a href="https://preorder.brity.fm" className="inline-block no-underline" target="_blank" rel="noopener noreferrer">
          <p className="preorder-text text-white text-2xl font-bold animate-grow-shrink" style={{ fontFamily: 'Avenir, sans-serif' }}>
            Preorder Hopium Album Now - Get 50% Tax Reduction! 🎵💰
          </p>
        </a>
      </div>
      <FlyAnimation />
      <h1 className={`sisi-headline absolute z-10 ${isAnimating ? 'animate-shake' : ''}`}>
        {displayText}
      </h1>
      <div className="picture-frame absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/3 h-2/3 overflow-hidden">
        <div className="image-container w-full h-full" style={{
          backgroundImage: `url(${heroBackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          border: '8px solid white',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
        }} />
      </div>
      <img src={waveSvg} alt="Wave" className="wave-separator absolute bottom-0 left-0 w-full" style={{ zIndex: 2 }} />
    </div>
  );
}

export default Hero;